@import 'variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

*::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}

div::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-right: 1px solid #cdcdcd !important;
  border-bottom: 1px solid #cdcdcd !important;
  border-left: 1px solid #dedede !important;
  border-top: 1px solid #dedede !important;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #bbb !important;
  border-radius: 10px !important;
  border: 3px solid transparent !important;
  background-clip: content-box !important;
}

h1,
h2,
h3 {
  margin: 0;
  color: $secondary-color-00;
}

h1 {
  line-height: 48px;
}

a {
  color: $primary-color-00;
  text-decoration: none;
  &:hover {
    color: $primary-color-01;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary-color-00;
    &:hover {
      color: $secondary-color-02;
    }
  }
}

* {
  box-sizing: border-box;
}

p {
  color: $secondary-color-00;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

button {
  border: unset;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

@font-face {
  font-family: 'Good-Brush';
  src: local('Good-Brush'), url(../../public/fonts/Good-Brush.woff) format('woff');
}

@font-face {
  font-family: 'Goldplay';
  src: local('Goldplay'), url(../../public/fonts/Goldplay-SemiBold.woff) format('woff');
}

.hiddenChatBot {
  #chatbase-bubble-button, #chatbase-message-bubbles, #chatbase-bubble-window {
    display: none !important;
  }
}
